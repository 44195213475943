.navbar-brand-full {
  object-fit: fill;
  width: auto;
  border-radius: 4px;
  padding: 4px;
}

.navbar-brand-minimized {
  object-fit: contain;
  border-radius: 4px;
  padding: 4px;
}

.darkBackgroundLogo > img {
  background-color: #3A4248;
}

.lightBackgroundLogo > img {
  background-color: transparent;
}

input {
  font-size: 0.875rem;
  padding: 0.375rem 0;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

input:focus {
  outline: none;
}

.btn {
  border-radius: 2px !important;
}

table td {
  min-width: 200px;
}

table td p {
  margin: 0;
}

table td .product-table-image {
  width: 128px;
  height: 128px;
  object-fit: cover;
}

table td .banner-table-image {
  width: 100%;
  object-fit: contain;
}

.custom-file-label::after {
  content: "Selecionar" !important;
}

.custom-file-label #text {
  content: "Escolha uma imagem" !important;
}

.banner-form-image {
  width: 100%;
  object-fit: contain;
}

.logo-form-image {
  width: 50%;
  object-fit: contain;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.favicon-form-image {
  width: 10%;
  object-fit: contain;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.header-img {
  border: 1px solid #212121;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 16px !important;
}

#about {
  min-height: 200px;
  max-height: 350px;
  resize: vertical;
}

textarea#product-description {
  min-height: 200px;
  max-height: 350px;
  resize: vertical;
}

.order-detail-table-image {
  width: 150px;
  object-fit: contain;
}

label {
  font-weight: 500;
}

.order-detail-info-container {
  margin-bottom: 1em;
}

.order-detail-info-container p {
  margin: 0;
  font-size: 13px;
}

#add-option-value {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
}

#add-option-value span {
  font-size: 18px;
  margin-bottom: 2px;
}

#add-option-value:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

#add-option-value:focus {
  box-shadow: none;
  outline: none;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile:disabled + label{
  background-color: #ddd;
  color: #888;
  cursor: no-drop;
}

.inputfile + label {
  color: #333;
  background-color: transparent;
  padding: 0.5em 1em;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  cursor: pointer;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.inputfile:disabled + label:hover {
  background-color: #ccc;
}
